<template>
  <div class="rule">RULE</div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {


    return {

    }

  }
})
</script>

<style scoped lang="less">
.rule {
}
</style>




